@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/Mulish-Regular.woff2') format('woff2'),
    url('../fonts/Mulish-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/Mulish-SemiBold.woff2') format('woff2'),
    url('../fonts/Mulish-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/Mulish-Bold.woff2') format('woff2'),
    url('../fonts/Mulish-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/Mulish-ExtraBold.woff2') format('woff2'),
    url('../fonts/Mulish-ExtraBold.woff') format('woff');
}