.auth {
    height: 100%;

    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;

    text-align: center;
}

.form {
    margin-top: 2rem;
    flex: 1 0 auto;

    display: flex;
    flex-direction: column;
}

.group + .group {
    margin-top: 1rem;
}

.submit {
    margin-top: auto;
}

.adornment {
    font-size: 2rem;
}
