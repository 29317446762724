.container {
    height: 100%;
    flex: 1 0 auto;

    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
}

.img {
    max-width: 100%;
    width: auto;
    flex: 1 0 auto;
    align-self: center;
}

.description {
    line-height: 1.5;
}

.input {
    margin-top: 1.5rem;
}

.linkIcon {
    font-size: 2rem;
}

.copyButton {
    font-size: 1rem;
    color: var(--color-text);
}
