:root {
    --app-height: calc(100vh - calc(100vh - 100%));

    --ff-base: Mulish, Arial, sans-serif;

    --fs-base: 100%;

    --fs-xl: 1.5rem;
    --fs-lg: 1.125rem;
    --fs-md: 1rem;
    --fs-sm: 0.875rem;
    --fs-xs: 0.6875rem;

    --fw-medium: 600;
    --fw-semibold: 700;
    --fw-bold: 800;

    --fs-h1: var(--fs-xl);
    --fs-h2: var(--fs-lg);
    --fs-h3: var(--fs-md);

    --fs-text: var(--fs-md);
    --fs-text-lg: var(--fs-lg);
    --fs-text-sm: var(--fs-sm);
    --fs-caption: var(--fs-xs);
    --fs-button: var(--fs-md);

    --color-white: #ffffff;
    --color-gray-0: #f3f4f6;
    --color-gray-10: #ebeef0;
    --color-gray-20: #eeeeee;
    --color-gray-30: #9fa0a9;
    --color-gray-40: #8a8c8f;
    --color-gray-50: #727272;
    --color-black: #201e19;

    --color-accent: #ff8745;
    --color-accent-light: #fcece6;

    --color-danger: #e04833;
}
