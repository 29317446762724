.page {
    flex: 1 0 auto;
}

.container {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    padding-left: 1.5rem;
    padding-right: 1.5rem;

    text-align: center;
}

.illustration {
    flex: 1 1 247px;
    width: auto;
    height: 0;
    max-height: 296px;
}

.content {
    flex: 1 0 auto;
}

.description {
    margin-top: 1rem;
    margin-bottom: 0;

    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: -0.01em;
}
