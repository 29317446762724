.start {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    flex: 1 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    padding-left: 1rem;
    padding-right: 1rem;
}

.caption {
    font-weight: var(--fw-medium);

    color: var(--color-gray-50);
}

.partnerCard {
    position: relative;

    margin-top: 2rem;

    padding: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: var(--color-white);

    border-radius: 2rem;

    &:after {
        content: "";

        position: absolute;
        top: -1.9rem;
        right: 0.375rem;

        display: block;
        width: 2.25rem;
        height: 2.8125rem;

        background-image: url("./flag.svg");
        background-size: 100%;
    }
}

.partnerLogoWrapper {
    padding: 1rem;
}

.partnerLogo {
    width: auto;
    height: 3rem;
}

.partnerDescription {
    margin: 0;
    padding: 1rem 2rem;

    font-size: var(--fs-text);
    font-weight: var(--fw-semibold);

    text-align: center;
    letter-spacing: -0.01em;

    border-top: 1px solid #f0f0f0;
}

.content {
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.field,
.button {
    width: 15.25rem;
}

.button {
    margin-top: 2rem;
}

@media screen and (max-height: 639px) {
    .partnerCard {
        margin-top: 1rem;
    }

    .content {
        margin-top: 1.5rem;
    }

    .button {
        margin-top: 1rem;
    }
}
