.button {
    display: inline-flex;
    align-items: center;

    height: 3rem;

    font-size: 1rem;
    letter-spacing: -0.01em;

    border-radius: 1.5rem;

    &:focus-visible {
        box-shadow: 0 0 0 2px rgba(86, 103, 137, 0.5);
    }
}

.button--primary {
    justify-content: center;

    padding: 0.5rem 2rem;

    font-weight: var(--fw-semibold);

    background: var(--color-black);
    color: var(--color-white);
}

.button--secondary {
    padding: 0.5rem;

    font-weight: var(--fw-medium);

    color: var(--color-black);
    background: var(--color-white);

    .label {
        border-bottom: 1px solid var(--color-black);
    }
}

.button--third {
    justify-content: center;

    padding: 0.5rem 2rem;

    font-weight: var(--fw-medium);

    color: var(--color-gray-40);
    background: transparent;

    border: 1px solid rgba(86, 103, 137, 0.26);
}

.button--outline {
    justify-content: center;

    padding: 0.5rem 2rem;

    font-weight: var(--fw-semibold);

    background: transparent;
    color: var(--color-black);

    border: 2px solid var(--color-black);
}

.button--disabled {
    opacity: 0.3;
    cursor: default;
}

.button--block {
    width: 100%;
}

.icon {
    margin-right: 0.75rem;

    width: 2rem;
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.25rem;

    color: var(--color-accent);
    background-color: var(--color-accent-light);

    border-radius: 50%;
}
