@import-normalize;

@import "variables";
@import "mixins";
@import "fonts";

html {
    font-size: var(--fs-base);
    height: 100%;

    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    font-size: var(--fs-text);
    line-height: 1.365;
    font-family: var(--ff-base);
    font-weight: 400;

    background: var(--color-gray-0);
    color: var(--color-black);

    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.body--modal-open {
    overflow: hidden;
}

*,
*:after,
*:before {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

#root {
    height: 100%;
}

button {
    @include reset-button;
}

a {
    @include reset-link;
}

ul {
    @include reset-list;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.mt {
    &--1 {
        margin-top: 1rem;
    }

    &--2 {
        margin-top: 1.5rem;
    }

    &--3 {
        margin-top: 2rem;
    }

    &--4 {
        margin-top: 3rem;
    }

    &--auto {
        margin-top: auto;
    }
}

.mb {
    &--1 {
        margin-bottom: 1rem;
    }

    &--2 {
        margin-bottom: 1.5rem;
    }

    &--3 {
        margin-bottom: 2rem;
    }

    &--4 {
        margin-bottom: 3rem;
    }

    &--auto {
        margin-bottom: auto;
    }
}

.align {
    &--left {
        text-align: left;
        align-self: flex-start;
    }

    &--center {
        text-align: center;
        align-self: center;
    }

    &--right {
        text-align: right;
        align-self: flex-end;
    }
}
