.onboarding {
    padding-bottom: 7rem;
}

.screen {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    max-width: 20rem;
    text-align: center;
}

.content h1 {
    height: 3.75em;

    margin-top: 4vh;
    margin-bottom: 0;

    font-size: var(--fs-h1);
    font-weight: var(--fw-semibold);
    line-height: 1.25;
    letter-spacing: -0.01em;
}

.buttonNext {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 3.5rem;
    height: 3.5rem;

    font-size: 1.5rem;

    color: var(--color-white);
    background-color: var(--color-black);

    border-radius: 1rem;

    cursor: pointer;
}