.carousel,
.container {
    position: relative;
    width: 100%;
}

.container {
    padding-bottom: 6vh;
}

.viewport {
    overflow: hidden;
    width: 100%;
}

.viewport:global(.is-draggable) {
    cursor: move;
    cursor: grab;
}

.viewport:global(.is-dragging) {
    cursor: grabbing;
}

.inner {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
}

.slide {
    position: relative;
    min-width: 100%;
    padding-left: 10px;
}

.scrollbar {
    position: absolute;
    left: 50%;
    bottom: 0;

    width: 10rem;
    height: 0.625rem;

    background-color: var(--color-gray-10);

    border-radius: 0.5rem;

    transform: translateX(-50%);
}

.indicator {
    height: 100%;

    background-color: var(--color-accent);

    border-radius: 0.5rem;

    transition: transform ease-in-out 0.15s;
}
