.heading {
    margin: 0;
    font-weight: var(--fw-semibold);
    line-height: 1.23;
    letter-spacing: -0.01em;

    &--h1 {
        font-size: var(--fs-h1);
    }

    &--h2 {
        font-size: var(--fs-h2);
    }


    &--h3,
    &--p {
        font-size: var(--fs-h3);
    }
}

.paragraph {
    margin: 0;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.01em;

    &--small {
        font-size: var(--fs-text-sm);
    }

    &--large {
        font-size: var(--fs-text-lg);
    }

    &--default {
        font-size: var(--fs-text);
    }
}

.caption {
    margin: 0;
    font-weight: 400;
    letter-spacing: -0.01em;

    &--small {
        font-size: var(--fs-caption);
    }

    &--default {
        font-size: var(--fs-text-sm);
    }
}
