.container {
    height: 100%;

    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
}

.heading {
    margin-top: 3rem;
    margin-bottom: 1.5rem;

    font-size: var(--fs-h2);
    line-height: 1.22;
    font-weight: var(--fw-semibold);

    &:first-child {
        margin-top: 0;
    }
}

.summary {
    padding: 1.5rem 1rem 1rem;

    background-color: var(--color-white);

    border-radius: 2rem;
}

.subheading {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #f0f0f0;
}

.table {
    margin: 0;
    width: 100%;
    border-collapse: collapse;

    td {
        padding-top: 1rem;

        &:last-child {
            text-align: right;
        }
    }
}

.document {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;
}

.links {
    flex: 1 0 auto;
    list-style: none;
    padding: 0;
    margin: 0;

    li + li {
        margin-top: 1rem;
    }
}

.documentLink {
    text-transform: capitalize;
}

.button {
    margin-top: 2rem;
}

.provider {
    width: 100%;
    height: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--color-white);

    border-radius: 1.5rem;

    img {
        height: 3rem;
    }
}

.header {
    flex: 0 0 auto;

    padding: 1rem;
}

.content {
    flex: 1 1 auto;

    padding: 0.5rem;

    font-size: var(--fs-text-sm);
    line-height: 1.25rem;
    color: var(--color-gray-40);

    text-align: center;
    overflow-y: auto;

    code {
        margin: 0;

        font-size: var(--fs-h1);
        font-weight: var(--fw-bold);
        font-family: var(--ff-base);
        letter-spacing: 0.3em;

        color: var(--color-black);
    }
}

.footer {
    margin-top: 1rem;

    flex: 0 0 auto;

    display: flex;
    justify-content: space-between;

    padding: 1rem 0.5rem 0.5rem;

    border-top: 1px solid #f0f0f0;
}
