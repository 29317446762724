@mixin reset-button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 1em;
    font-family: inherit;
    outline: none;
    border-radius: 0;
    background: none;
    box-shadow: none;
    cursor: pointer;
}

@mixin reset-link {
    text-decoration: none;

    &:hover,
    &:focus,
    &:visited {
        text-decoration: none;
    }
}

@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
}