.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: 1000;
    display: flex;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0;
    animation-fill-mode: both;

    &--center {
        padding: 1rem;
        align-items: center;
        justify-content: center;
    }

    &--bottom {
        align-items: flex-end;
        justify-content: stretch;
    }

    &--closing {
        opacity: 1;
    }
}

.content {
    position: relative;
    background: var(--color-white);

    transition: transform ease 0.15s;

    &--center {
        border-radius: 1rem;
    }

    &--bottom {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        width: 100%;
        border-radius: 2rem 2rem 0 0;

        &:after {
            content: "";
            position: absolute;
            height: 100%;
            top: 100%;
            left: 0;
            right: 0;
            background-color: var(--color-white);
        }
    }
}

.dragZone {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 1.5rem;

    &:after {
        content: "";

        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        width: 4rem;
        height: 0.25rem;

        background: var(--color-gray-20);

        border-radius: 2px;

        transform: translate(-50%, -50%);
    }
}

.transition--fade {
    opacity: 0;
}

.transition--fade-in {
    animation: fade ease-in 0.35s;
}

.transition--fade-exit {
    animation: fade ease-out 0.35s reverse;
}

.transition--slide-bottom {
    transform: translateY(100%);
}

.transition--slide-bottom-in {
    animation: slide-bottom ease-out 0.35s;
}

.transition--slide-bottom-exit {
    animation: slide-bottom-out ease-in 0.35s;
}

.is-open {
    transform: translateY(0%);
    opacity: 1;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slide-bottom {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slide-bottom-out {
    to {
        transform: translateY(100%);
    }
}
