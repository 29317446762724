.header {
    position: relative;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    padding-bottom: 0.75rem;

    &--auth {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    &--contract {
        position: fixed;
        z-index: 1000;

        width: 100%;
        max-width: 425px;
        height: 12vh;
        
        background-color: var(--color-gray-0);
        border-bottom: 0.5px solid #ababab;
    }
}

.back {
    position: absolute;
    bottom: 0.375rem;
    left: 1rem;

    width: 2rem;
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.5rem;

    color: var(--color-black);
    background: var(--color-white);

    border-radius: 50%;
}
