.header {
    flex: 0 0 auto;

    padding: 1rem;
}

.content {
    flex: 1 1 auto;

    padding: 0.5rem 0.5rem 2rem;

    font-size: var(--fs-text-sm);
    line-height: 1.25rem;
    color: var(--color-gray-40);

    text-align: center;
    overflow-y: auto;

    code {
        margin: 0;

        font-size: var(--fs-h1);
        font-weight: var(--fw-bold);
        font-family: var(--ff-base);
        letter-spacing: 0.3em;

        color: var(--color-black);
    }
}

.footer {
    margin-top: -1rem;

    flex: 0 0 auto;

    display: flex;
    justify-content: space-between;

    padding: 1rem 0.5rem 0.5rem;

    border-top: 1px solid #f0f0f0;
}
