.info {
    height: 100%;

    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
}

.fieldset {
    border: 0;
    padding: 0;
    margin: 0;

    & + .fieldset {
        margin-top: 3rem;
    }
}

.group + .group {
    margin-top: 1rem;
}

.group + .groupCheckbox,
.groupCheckbox + .groupCheckbox {
    margin-top: 1.5rem;
}

.submit {
    margin-top: 2rem;
}

.box {
    composes: box from '../../components/Select/Select.module.scss';

    img {
        display: block;

        width: auto;
        height: 3rem;
    }
}

.option {
    width: 100%;
    height: 3rem;
    
    display: flex;
    align-items: center;
    justify-content: center;
}