.switch {
    position: relative;

    width: 100%;
    height: 3rem;

    background-color: var(--color-gray-10);

    border: 3px solid var(--color-gray-10);

    border-radius: 1.5rem;
}

.indicator {
    position: absolute;
    top: 0;
    left: 0;

    height: 2.625rem;

    background: var(--color-white);

    border-radius: 1.5rem;

    transition: transform ease-in-out 0.15s;

    box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.container {
    position: relative;
    z-index: 2;

    width: 100%;
    height: 100%;

    display: grid;
    grid-gap: 0.1875rem;

    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
}

.option {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 1.5;
    font-weight: var(--fw-semibold);
    letter-spacing: -0.01em;

    color: var(--color-gray-30);

    transition: color ease-out 0.15s;
}

.option--selected {
    color: var(--color-black);
}
