.loader {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0.2rem;
    overflow: hidden;
    z-index: 10000;
    transition: opacity linear 0.15s, visibility linear 0.15s;
}

.item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background: var(--color-accent);
    transform: translateX(-100%);
    animation-fill-mode: both;
    animation: loading ease-in-out infinite 1.8s;
}

.delayedItem {
    composes: item;
    animation-delay: 1.3s;
}

@keyframes loading {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}
