.control {
    width: 100%;
    height: 3rem;

    padding: 0.25rem 0;

    display: flex;

    font-size: var(--fs-text);

    background: var(--color-white);

    border: 1px solid transparent;
    border-radius: 2rem;

    overflow: hidden;

    &--large {
        height: 4rem;

        font-size: var(--fs-text-lg);

        .input {
            font-weight: var(--fw-semibold);
        }
    }

    &--outline {
        border: 1px solid #f0f0f0;
    }

    &--focused,
    &--filled {
        border: 1px solid var(--color-accent);
    }

    &--error {
        border: 1px solid var(--color-danger);
    }
}

.adornment {
    flex: 0 0 auto;

    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    &--start {
        composes: adornment;

        margin-left: 0.5rem;
        border-right: 1px solid transparent;
    }

    &--end {
        composes: adornment;
        margin-right: 0.5rem;
        border-left: 1px solid transparent;
    }

    &--string {
        margin: 0;

        &.adornment--start {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        &.adornment--end {
            padding-left: 0.75rem;
            padding-right: 1rem;
        }

        font-weight: var(--fw-semibold);
        letter-spacing: -0.01em;

        border-color: #f0f0f0;
    }
}

.input {
    flex: 1 1 auto;

    width: 100%;
    height: 100%;
    padding: 0 1rem;

    font-family: inherit;
    font-size: inherit;
    font-weight: 400;

    letter-spacing: -0.01em;

    color: var(--color-black);

    border: 0;
    box-shadow: none;

    &:focus {
        outline: none;
    }

    &::-webkit-input-placeholder {
        color: var(--color-gray-30);
        opacity: 1;
    }
    &::-moz-placeholder {
        color: var(--color-gray-30);
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: var(--color-gray-30);
        opacity: 1;
    }
    &:-moz-placeholder {
        color: var(--color-gray-30);
        opacity: 1;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &--align-left {
        text-align: left;
    }

    &--align-right {
        text-align: right;
    }
}

.error {
    composes: adornment;

    color: var(--color-danger);
    margin-right: 0.8rem;
}
