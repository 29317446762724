.app {
    width: 100vw;
    max-width: 425px;
    min-height: var(--app-height);

    margin: 0 auto;

    display: grid;
    grid-auto-flow: row;
    grid-template-rows: minmax(8vh, 12vh) 1fr;
    grid-auto-columns: 100%;
    grid-gap: 2rem;

    padding-bottom: 1.5rem;
}

.main {
    grid-row: 2 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-height: 639px) {
    .app {
        grid-gap: 1rem;
    }
}
