.error {
    display: flex;
    flex-direction: column;

    padding: 1rem;

    height: 100%;
    max-height: 70vh;
}

.header {
    flex: 0 0 auto;

    padding: 0.5rem 0.5rem 1rem;
}

.content {
    flex: 1 1 auto;

    padding: 0.5rem;

    font-size: var(--fs-text-sm);
    line-height: 1.25rem;
    color: var(--color-gray-40);

    overflow-y: auto;
}

.footer {
    margin-top: 1rem;

    flex: 0 0 auto;

    display: flex;
    justify-content: space-between;

    padding: 1rem 0.5rem 0.5rem;

    border-top: 1px solid #f0f0f0;
}
