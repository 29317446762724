.icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1em;
    height: 1em;

    &--accent {
        color: var(--color-accent);
        background-color: var(--color-accent-light);

        border-radius: 50%;

        .svg {
            font-size: 0.625em;
        }
    }
}

.svg {
    display: block;
    width: 1em;
    height: 1em;
}