.control {
    position: relative;

    width: 100%;
    height: 3rem;

    padding: 0.25rem 0;

    display: flex;

    font-size: var(--fs-text);

    background: var(--color-white);

    border: 1px solid transparent;
    border-radius: 2rem;

    overflow: hidden;

    &--focused,
    &--filled {
        border: 1px solid var(--color-accent);
    }
}

.box {
    display: block;
    
    flex: 1 1 auto;
    align-self: center;

    width: 100%;
    height: auto;
    padding: 0 1rem;

    font-weight: 400;

    letter-spacing: -0.01em;

    color: var(--color-black);
}

.placeholder {
    composes: box;

    color: var(--color-gray-30);    
}

.arrow {
    position: absolute;
    top: 50%;
    right: 1rem;

    font-size: 1.25rem;

    color: var(--color-black);

    transform: translateY(-50%);
}

.options {
    display: flex;
    flex-direction: column;
}

.item {
    padding: 0.5rem 1rem;

    & + .item {
        border-top: 1px solid #f0f0f0;
    }
}

.option {
    width: 100%;
    height: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--color-text);
}