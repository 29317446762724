.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    font-size: 1.5rem;
    animation: spinner linear 1s infinite;
}

.label {
    margin-left: 0.5rem;
    font-size: var(--fs-text-sm);
}

@keyframes spinner {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
