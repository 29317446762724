.control {
    display: flex;
    align-items: flex-start;
}

.input {
    composes: visually-hidden from "../../assets/styles/global.scss";

    &:focus ~ .icon {
        box-shadow: 0 0 0 2px rgba(86, 103, 137, 0.5);
    }

    &:checked ~ .check {
        display: block;
    }

    &:checked ~ .box {
        display: none;
    }
}

.icon {
    font-size: 1.75rem;

    border-radius: 0.5rem;
}

.check {
    composes: icon;

    display: none;

    color: var(--color-accent);
}

.box {
    composes: icon;

    display: block;

    color: var(--color-black);
}

.label {
    margin-top: 0.1875rem;
    margin-left: 0.75rem;
    line-height: 1.5rem;
}
