.verification {
    height: 100%;

    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
}

.providersList {
    flex: 1 0 auto;

    margin-bottom: 2rem;
}

.item + .item {
    margin-top: 1rem;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 4rem;

    background-color: var(--color-white);

    border: 1px solid transparent;
    border-radius: 2rem;
}

.terms {
    display: flex;
    flex-direction: column;

    padding: 1rem;

    height: 100%;
    max-height: 70vh;
}