.container {
    height: 100%;

    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
}

.totalAmount {
    margin-top: 1rem;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 1rem;
    height: 3rem;

    font-weight: var(--fw-medium);

    border: 1px solid #ABABAD;
    border-radius: 1.5rem;
}

.totalAmountValue {
    font-size: var(--fs-text-lg);
    font-weight: var(--fw-bold);
}

.payment {
    margin: 1.5rem auto;

    padding: 0.5rem;

    display: flex;
    align-items: center;

    font-size: var(--fs-text);
    font-weight: var(--fw-medium);
    line-height: 1.375;
}

.paymentSum {
    margin-left: 0.5rem;

    font-size: var(--fs-h1);
    font-weight: var(--fw-semibold);
    letter-spacing: -0.01em;
}

.customSum {
    padding: 1rem 1rem 0;

    background: var(--color-white);

    border-radius: 1.75rem;
}

.headline {
    padding-bottom: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.arrow {
    font-size: 1.5rem;
}

.switch {
    font-size: var(--fs-text-sm);
}

.content {
    padding-top: 1rem;
    padding-bottom: 1.5rem;

    border-top: 1px solid #f0f0f0;
}

.control {
    margin-top: 1rem;
}

.button {
    margin-top: auto;
}
