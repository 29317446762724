.container {
    height: 100%;

    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
}

.variantsList {
    flex: 1 0 auto;

    margin-top: 1.5rem;
    margin-bottom: 2rem;
}

.item + .item {
    margin-top: 0.5rem;
}

.control {
    composes: visually-hidden from "../../assets/styles/global.scss";

    &:checked ~ .label {
        border-color: var(--color-accent);
    }

    &:focus-visible ~ .label {
        box-shadow: 0 0 0 2px rgba(86, 103, 137, 0.5);
    }
}

.variant {
    display: block;
}

.label {
    padding: 1.25rem 1.5rem;

    display: grid;
    grid-gap: 0.5rem;
    grid-template-areas: "type time" "payment interest";

    background: var(--color-white);

    border: 1px solid transparent;
    border-radius: 2rem;
}

.type {
    grid-area: type;

    font-weight: var(--fw-medium);
}

.time {
    grid-area: time;

    justify-self: end;

    font-weight: var(--fw-bold);
}

.payment {
    grid-area: payment;

    font-size: var(--fs-text-sm);
    color: var(--color-gray-30);

    strong {
        margin-left: 0.5rem;

        font-weight: var(--fw-semibold);

        color: var(--color-gray-50);
    }
}

.interest {
    grid-area: interest;

    justify-self: end;

    padding: 0 0.25rem;

    font-size: var(--fs-caption);
    font-weight: var(--fw-semibold);
    line-height: 1.25rem;

    color: var(--color-white);
    background-color: var(--color-accent);

    border-radius: 1rem;
}
